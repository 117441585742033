<template>
  <fieldset>
    <DocFieldsZuEgrnOther
      :modelValue="data"
      :document-type-id="documentTypeId"
      :document-reg-num="documentRegNum"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields>
        <UniversalSelectField
          data-qa="constructionType"
          :modelValue="modelValue.docViewConstruction"
          @update:modelValue="update('docViewConstruction', $event, v$.docViewConstruction)"
          :key-prop="'id'"
          :displayProp="'displayName'"
          @filter="fetchWithDelay($event)"
          :hasError="v$.docViewConstruction.$error"
          :options="docViewConstructions"
        >
          <label class="label label--required">Вид строительства</label>
          <template #validation>
            <ValidationMsg :errors="v$.docViewConstruction.$errors"></ValidationMsg>
          </template>
        </UniversalSelectField>
        <TextField
          :modelValue="modelValue.nameFromPD"
          @update:modelValue="update('nameFromPD', $event, v$.nameFromPD)"
          :errors="v$.nameFromPD.$errors"
          data-qa="stageName"
        >
          <label class="label label--required"
            >Наименование объекта (этапа)</label
          >
        </TextField>
        <MultiSubjectField
            :modelValue="modelValue.developers"
            @update:modelValue="update('developers', $event, v$.developers)"
            :errors="v$.$error ? v$.developers.$errors : []"
            :itemValidationRule="developerRules"
            data-qa="developer"
        >
          <label class="label label--required">Застройщик</label>
          <template #add>Добавить</template>
        </MultiSubjectField>
      </template>
      <template #additional-fields-bottom>
        <MultiSelectField
          :modelValue="modelValue.oks"
          @update:modelValue="update('oks', $event, v$.oks)"
          :options="vectors"
          @reload="fetchWithDelay"
          :itemValidationRule="itemValidationRule"
          data-qa="capitalObjects"
          keyProp="externalId"
        >
          <label class="label">Объекты капитального строительства</label>
          <template #add>Добавить</template>
        </MultiSelectField>
      </template>
    </DocFieldsZuEgrnOther>
  </fieldset>
</template>

<script>
import DocRV from "../../../models/documents/docRV";
import DocFieldsZuEgrnOther from "./DocFieldsZuEgrnOther";
import TextField from "../../basic/form/TextField.vue";
import ValidationMsg from "../../basic/form/select/ValidationMsg";
import useDocViewConstructionRefs from "./hooks/docViewConstructionsHook";
import { toRefs } from "vue";
import { helpers, required } from "@vuelidate/validators";
import useValidator from "./validator";
import MultiSelectField from "../../basic/MultiSelectField.vue";
import { useVectorOks } from "@/hooks/vector";
import subjectValidators from '../../../models/subjectValidators';
import MultiSubjectField from "../../basic/form/MultiSubjectField.vue";
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";
export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocRV(),
    },
    documentTypeId:{
      type: Number,
      required: true
    },
    documentRegNum:{
      type: String,
      required: false
    }
  },
  components: {
    UniversalSelectField,
    DocFieldsZuEgrnOther,
    TextField,
    MultiSelectField,
    ValidationMsg,
    MultiSubjectField
  },
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);

    const { docViewConstructions } = useDocViewConstructionRefs();
    const { vectors, fetchWithDelay } = useVectorOks();

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    let rules = {
      docViewConstruction: {
        required: helpers.withMessage("Заполните поле", required),
      },
      nameFromPD: {
        required: helpers.withMessage("Заполните поле", required),
      },
      developers: {
        required: helpers.withMessage("Заполните поле", required),
      },
    };

    let developerRules = {
      ...subjectValidators
    }

    let itemValidationRule = {
      externalId: helpers.withMessage("Заполните или удалите неиспользуемое поле", (value) => {
        return value !== null && value["externalId"] !== undefined
      })
    }

    const v$ = useValidator(data, rules);

    return { data, update, v$, docViewConstructions, vectors, fetchWithDelay, itemValidationRule, developerRules };
  },
};
</script>